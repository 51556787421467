<template>
	<section class="h-100">
		<CourseEditorSlider
			@selectWidget="$emit('selectWidget', $event)"
		></CourseEditorSlider>
		<section
			v-if="chapters.length !== 0 && !!activePage !== false"
			class="course-editor-content h-100"
		>
			<CourseEditorChapterContent
				:chapter="selectedChapter"
				@getCourse="$emit('getCourse')"
			>
				<template v-if="selectedChapterTitle" #name>
					{{ selectedChapterTitle }}
				</template>
				<template #content>
					<CourseEditorPageContent
						class="course-editor-content__single-chapter-card"
						:page="activePage"
						:chapter="selectedChapter"
						@saveContent="$emit('saveContent', $event)"
						@removePage="$emit('removePage', $event)"
						@changeBlkIndex="$emit('changeBlkIndex', $event)"
						@editBlock="$emit('editBlock', $event)"
						@removeBlock="$emit('removeBlock', $event)"
						@getCourse="$emit('getCourse')"
					>
						<template v-if="activePage" #name>{{
							activePageTitle
						}}</template>
					</CourseEditorPageContent>
				</template>
			</CourseEditorChapterContent>
		</section>
	</section>
</template>
<script>
import CourseEditorSlider from "@/views/admin/course-editor/CourseEditorSlider.vue";
import CourseEditorChapterContent from "@/views/admin/course-editor/CourseEditorChapterContent.vue";
import CourseEditorPageContent from "@/views/admin/course-editor/CourseEditorPageContent.vue";
export default {
	name: "CourseEditorContent",
	props: {
		chapters: {
			type: Array,
			required: true
		},
		activePage: {
			type: Object,
			require: true
		},
		selectedChapter: {
			type: Object,
			require: true
		}
	},
	components: {
		CourseEditorSlider,
		CourseEditorChapterContent,
		CourseEditorPageContent
	},
	computed: {
		selectedChapterTitle() {
			return this.selectedChapter?.title;
		},
		activePageTitle() {
			return this.activePage?.title;
		},
		selectedChapterPages() {
			return this.selectedChapter?.pages;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	}
};
</script>
