<template>
	<article class="course-editor-content__chapters-container">
		<!-- <v-row class="w-100 m-0">
			<v-col cols="8">
				<h3 v-if="!isChapterNameEdited">
					<slot name="name"></slot>
				</h3>
				<div v-else class="d-flex flex-row align-center">
					<InputTextField class="ma-0" v-model="newChapterTitle"
						><template #label
							>New chapter's title</template
						></InputTextField
					>
					<div class="ml-4">
						<v-icon @click="saveChapterName"
							>mdi-check-outline</v-icon
						>
					</div>
				</div>
			</v-col>
			<v-col cols="4">
				<div class="d-flex flex-row justify-end">
					<span>
						<v-icon
							v-if="!isChapterNameEdited"
							@click="setChapterNameEdited(true)"
							>mdi-pencil-outline</v-icon
						>
						<v-icon v-else @click="cancelEditting"
							>mdi-close</v-icon
						>
					</span>
				</div>
			</v-col>
		</v-row> -->
		<v-row class="w-100 m-0">
			<v-col>
				<slot name="content"></slot>
			</v-col>
		</v-row>
	</article>
</template>
<script>
import { put } from "@/util/requests/put";

export default {
	name: "CourseEditorChapterContent",
	props: {
		chapter: {
			type: Object,
			require: true
		}
	},
	computed: {
		chapterId() {
			return this.chapter?.id;
		},
		chapterName() {
			return this.chapter?.title;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			isChapterNameEdited: false,
			newChapterTitle: this.chapterName
		};
	},
	watch: {
		chapterId: function () {
			if (this.isChapterNameEdited === true) {
				this.isChapterNameEdited = false;
			}
		}
	},
	methods: {
		setChapterNameEdited(val) {
			this.isChapterNameEdited = val;
		},
		cancelEditting() {
			this.setChapterNameEdited(false);
			this.newChapterTitle = "";
		},
		async saveChapterName() {
			await put("/chapter", this.chapter.id, {
				course_id: this.chapter.course_id,
				title: this.newChapterTitle,
				content: this.chapter.content,
				sort_order: this.chapter.sort_order,
				custom: this.chapter.custom,
				category_id: this.chapter.category_id
			});
			await this.$emit("getCourse");
			this.cancelEditting();
		}
	}
};
</script>
